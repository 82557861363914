import gql from "graphql-tag";

export const DEFAULT_BRANCH = gql`
  query Root {
    root {
      id
      name
      color
    }
  }
`;

export const EMPLOYEES_BRANCH = gql`
  query FilterEmployees($criteria: EmployeeFilterCriteria!) {
    filterEmployees(criteria: $criteria) {
      id
      firstName
      lastName
      profile {
        imageUrl
      }
      employment {
        jobInfo {
          name
        }
      }
    }
  }
`;

export const BRANCH = gql`
  query Branch {
    branches {
      id
      name
      color
    }
  }
`;

export const FILTER_BRANCH = gql`
  query FilterBranches($criteria: BranchFilterCriteria!) {
    filterBranches(criteria: $criteria) {
      id
      name
      color
    }
  }
`;
